<template lang="">
        <PropertyList />
</template>
<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';

export default {
    name:"teklif",
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: 'Tekliflerim', route: '/teklif' },
        ]);
    },
    components:{
        PropertyList: () =>import('@/components/properties/PropertyList.vue')
    }
}
</script>
<style lang="">
    
</style>